import { BlobServiceClient } from '@azure/storage-blob';
import { tenantModule } from '../../store/modules/tenant/tenant.module';

class FileService {
  blobSasUrl =
    'https://handycatssa.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-08-03T18:45:50Z&st=2022-08-03T10:45:50Z&spr=https&sig=8vOjY54dJqpzomuQwwlBfgtIjfRUlAZpCYjm%2BRY76I4%3D';
  blobServiceClient: BlobServiceClient;

  constructor() {
    this.blobServiceClient = new BlobServiceClient(this.blobSasUrl);
  }

  uploadFiles = async (file: any, progress: any, error: any, options: any) => {
    try {
      console.log(options);
      progress(5);
      const containerClient = this.blobServiceClient.getContainerClient(tenantModule.apiKey as string);
      await containerClient.createIfNotExists({ access: 'blob' });
      const fileName = `${file.name}-${Date.now()}`;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);

      progress(50);
      await blockBlobClient.uploadData(file, {
        onProgress: (event) => {
          console.log(event.loadedBytes);
          progress(100);
        },
        blobHTTPHeaders: { blobContentType: file.type },
      });

      return {
        url: blockBlobClient.url,
        name: file.name,
      };
    } catch (err) {
      error(err);
    }
  };

  // deleteFiles = async (fileList: any) => {
  //   try {
  //     if (fileList.selectedOptions.length > 0) {
  //       console.log('Deleting files...');
  //       for (const option of fileList.selectedOptions) {
  //         await this.containerClient.deleteBlob(option.text);
  //       }
  //       console.log('Done.');
  //     } else {
  //       console.log('No files selected.');
  //     }
  //   } catch (error: any) {
  //     console.log(error.message);
  //   }
  // };
}

export default function (instance: {
  extend: (arg0: {
    uploader: (file: any, progress: any, error: any, options: any) => Promise<{ url: string; name: any } | undefined>;
  }) => void;
}) {
  const fs = new FileService();
  instance.extend({
    uploader: fs.uploadFiles,
  });
}
